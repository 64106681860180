<div class="container" style="max-width: 80%;">
  <div class="row">
    <div class="col-md-12" style="max-height: 700px; min-height: 60px; margin-bottom: 60px;">
          <h4>Predicted labels</h4>
          <div class="hot">
            <button id="search_field" type="search" placeholder="Search" (click)="exportCSV($event)">Export file</button>
            <hot-table [settings]="hotSettings" [hotId]='resultID' [data]="tableData"></hot-table>
          </div>
      </div>
      <div class="col-md-7">
          <h4>Results Graph</h4>
          <img src="{{plotLink}}">
      </div>
  </div>
</div>
