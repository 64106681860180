<!doctype html>
<ng-template #regModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{title}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="white-space: pre-line;">
    {{message}}
  </div>
</ng-template>
    <div class="text-center frmcontainer">
        <form [formGroup]="form" class="form-signin" (ngSubmit)="onSubmit()" >
            <img class="mb-4" src="https://getbootstrap.com/docs/4.3/assets/brand/bootstrap-solid.svg" alt="" width="72" height="72">
            <h1 class="h3 mb-3 font-weight-normal">Please sign in</h1>
            <label for="inputEmail" class="sr-only">Email address</label>
            <input type="email" id="inputEmail" class="form-control" placeholder="Email address" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" formControlName="email">
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.required">Email is required</div>
            </div>
            <label for="inputPassword" class="sr-only">Password</label>
            <input type="password" id="inputPassword" class="form-control" placeholder="Password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" formControlName="password">
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
              <div *ngIf="f.password.errors.required">Password is required</div>
            </div>
            <button class="btn btn-lg btn-primary btn-block" type="submit">Sign in</button>
            <a [routerLink]="'/register'">No account? Register here!</a>
            <p class="mt-5 mb-3 text-muted">IDRProject</p>
        </form>
    </div>
