<!doctype html>
<ng-template #regModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{title}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="white-space: pre-line;">
    {{message}}
  </div>
</ng-template>
    <div class="text-center frmcontainer">
        <form [formGroup]="form" class="form-signin" (ngSubmit)="onSubmit()">
            <img class="mb-4" src="https://getbootstrap.com/docs/4.3/assets/brand/bootstrap-solid.svg" alt="" width="72" height="72">
            <h1 class="h3 mb-3 font-weight-normal">Register</h1>
            <label for="inputUniversity" class="sr-only">University</label>
            <select id= inputUniversity class="form-control" required="" formControlName="university" [ngClass]="{ 'is-invalid': submitted && f.university.errors }">
                <option>Arizona State University</option>
                <option>SUNY Albany</option>
            </select>
            <label for="inputCourse" class="sr-only">Course</label>
            <select id= inputCourse class="form-control" required="" formControlName="course" [ngClass]="{ 'is-invalid': submitted && f.course.errors }">
                <option>CEHC-200</option>
                <option>HSD-500</option>
            </select>
            <div *ngIf="submitted && f.university.errors" class="invalid-feedback">
              <div *ngIf="f.university.errors.required">University is required</div>
            </div>
            <label for="inputFName" class="sr-only">First Name</label>
            <input type="text" id="inputFName" class="form-control" placeholder="First Name" required="" formControlName="FName" [ngClass]="{ 'is-invalid': submitted && f.FName.errors }">
            <div *ngIf="submitted && f.FName.errors" class="invalid-feedback">
              <div *ngIf="f.FName.errors.required">First Name is required</div>
            </div>

            <label for="inputLName" class="sr-only">Last Name</label>
            <input type="text" id="inputLName" class="form-control" placeholder="Last Name" required="" formControlName="LName" [ngClass]="{ 'is-invalid': submitted && f.LName.errors }">
            <div *ngIf="submitted && f.LName.errors" class="invalid-feedback">
              <div *ngIf="f.LName.errors.required">Last Name is required</div>
            </div>

            <label for="inputEmail" class="sr-only">Email address</label>
            <input type="email" id="inputEmail" class="form-control" placeholder="Email address" required="" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.required">Email is required</div>
            </div>

            <label for="inputPassword" class="sr-only">Password</label>
            <input type="password" id="inputPassword" class="form-control" placeholder="Password" required="" formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
              <div *ngIf="f.password.errors.required">Password is required</div>
            </div>

            <label for="inputVPassword" class="sr-only">Verify Password</label>
            <input type="password" id="inputVPassword" class="form-control" placeholder="Verify Password" required="" formControlName="Vpassword" [ngClass]="{ 'is-invalid': submitted && f.Vpassword.errors }">
            <div *ngIf="submitted && f.Vpassword.errors" class="invalid-feedback">
              <div *ngIf="f.Vpassword.errors.required">Please enter the same passowrd</div>
            </div>

            <button class="btn btn-lg btn-primary btn-block" type="submit" style="margin-top: 10px;">Register</button>
            <p class="mt-5 mb-3 text-muted" >IDRProject</p>
        </form>
      </div>
