<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
  <a class="navbar-brand" [routerLink]="'/'">IDR</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
  </button>
  <div *ngIf="currentUser; else elseBlock" class="collapse navbar-collapse" id="navbarNav">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
          <a class="nav-link" [routerLink]="'/'">Home <span class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item">
          <a class="nav-link" [routerLink]="'tutorials'" >Tutorials</a>
      </li>
    </ul>
    <button *ngIf="currentUser.is_teacher" type="button" class="btn btn-dark" (click)="teacherView()">Teacher's Area</button>
    <button type="button" class="btn btn-dark" (click)="logout()">Logout</button>
  </div>
  <ng-template #elseBlock>
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
          <a class="nav-link"[routerLink]="'/'">Home <span class="sr-only">(current)</span></a>
        </li>
    </ul>
    <ul class="navbar-nav">
      <li class="nav-item active">
          <a class="nav-link" [routerLink]="'/login'">Login <span class="sr-only"></span></a>
        </li>
        <li class="nav-item active">
          <a class="nav-link" [routerLink]="'/register'">Register <span class="sr-only"></span></a>
        </li>
    </ul>
  </ng-template>
</nav>
