<div class="msb" id="msb">
  <!-- Main Menu -->
  <div class="side-menu-container">
      <nav class="nav flex-column">
          <a class="nav-link active" href="#">Welcome</a>
          <li class="panel panel-default" id="dropdown">
              <a class="nav-link dropdown-toggle" data-toggle="collapse" href="#clustering">Clustering</a>
              <div id="clustering" class="panel-collapse collapse">
                  <div class="panel-body">
                      <ul class="nav navbar-nav">
                          <li>
                              <a href="#">Kmenas</a>
                          </li>
                          <li>
                              <a href="#">DBScan</a>
                          </li>
                          <li>
                              <a href="#">Aglomerative</a>
                          </li>
                      </ul>
                  </div>
              </div>
          </li>
          <li class="panel panel-default" id="dropdown">
              <a class="nav-link dropdown-toggle" data-toggle="collapse" href="#classification">Classification</a>
              <div id="classification" class="panel-collapse collapse">
                  <div class="panel-body">
                      <ul class="nav navbar-nav">
                          <li>
                              <a href="#">K-Nearest-Neighbors</a>
                          </li>
                          <li>
                              <a href="#">Gaussian Naive Bayes</a>
                          </li>
                          <li>
                              <a href="#">Support Vector Machines</a>
                          </li>
                      </ul>
                  </div>
              </div>
          </li>
          <a class="nav-link" href="#">Something else</a>
      </nav>
  </div>
</div>
<!--main content wrapper-->
<div class="mcw">
  <!--navigation here-->
  <!--main content view-->
  <div class="cv">
      <div>
          <div class="inbox">
              <div class="inbox-sb">
</div>
              <div class="inbox-bx container-fluid">
                  <div class="row">
                      <div class="col-md-10">
                          <div>
                              <section id="introduction">
                                  <h4>
Introduction </h4>
                                  <p>
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum elementum ipsum vel nisi ultricies, ullamcorper congue nisl faucibus. Cras tempus, massa vel sagittis pharetra, tellus mi tempus nulla, sit amet porttitor mi mauris quis velit. Ut nec tempor libero, sit amet blandit nibh. Cras eget fringilla neque, in vulputate eros. Etiam vitae suscipit tellus. Aenean vehicula nibh a augue tincidunt, sit amet auctor odio volutpat. Vivamus rutrum nisi pretium tincidunt porta. Mauris non ligula non sapien dignissim ultrices at at sem.
Sed sit amet commodo nisl. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nam ac convallis tellus, nec aliquet odio. Sed sed hendrerit diam, at facilisis dolor. Etiam porttitor sapien et odio scelerisque, non pharetra eros consequat. Nam ornare ex fermentum leo tempus, non accumsan enim luctus. Cras nibh sapien, consectetur eu ornare id, volutpat eget urna. Morbi porttitor quam ac felis lobortis maximus. Nam sit amet augue at odio tincidunt commodo lobortis vitae arcu. Maecenas ultrices consectetur massa a sagittis. Aenean sit amet laoreet odio. Nulla arcu est, tempor quis lacus sit amet, egestas molestie risus. Cras id venenatis ante, vitae ultrices tortor.  </p>
                              </section>
                              <section id="overview">
                                  <h4>Overview</h4>
                                  <p>
Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nullam pharetra, dui vitae efficitur ullamcorper, enim orci cursus ligula, ut hendrerit libero nisi id lacus. Suspendisse quis elit ac enim consectetur tristique eget eu nibh. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed aliquam mauris vitae auctor maximus. Morbi et ante eget quam dapibus feugiat non id mauris. Vestibulum tristique semper sapien ut facilisis. Nullam pulvinar, metus et eleifend tristique, libero leo cursus ipsum, ut finibus libero elit ut arcu. Nunc pulvinar massa ac felis congue finibus. Nulla at metus elit. Morbi leo felis, eleifend vitae leo non, tristique bibendum lectus. Morbi eros velit, mattis ut urna at, pretium volutpat nisi. Nam porta et dolor at pellentesque. Suspendisse potenti. Aliquam erat volutpat. Aenean nisl ipsum, posuere a felis id, cursus condimentum felis.Fusce mattis ligula risus, sed ullamcorper velit tincidunt sed. Aliquam vulputate mi nec libero pellentesque facilisis sit amet ac leo. Praesent ut dignissim neque, vel ornare diam. In at semper tellus, vitae mattis odio. Pellentesque erat augue, maximus ac condimentum ullamcorper, sollicitudin ut odio. Nullam venenatis justo at mi ultricies, ut mollis nibh congue. Nam odio justo, pellentesque quis rutrum id, sollicitudin et nisi. Quisque quis aliquet quam, ac lacinia diam. Fusce quis tincidunt lorem, a ultricies est. Etiam ante nunc, dignissim sit amet porttitor vel, pretium et velit. Fusce varius leo in lacus scelerisque, at ullamcorper dui sagittis. Mauris eu leo nibh. Nam vitae sapien quis nisi condimentum maximus in in urna. Nam eget metus non nibh posuere pulvinar et et dolor. Morbi imperdiet mi non eleifend congue.   </p>
                              </section>
                              <section id="examples">
                                  <h4>Examples</h4>
                                  <p>
Vivamus dolor magna, auctor in dapibus ac, facilisis congue velit. Pellentesque ullamcorper nisl et dui dictum, ut pharetra leo tincidunt. Suspendisse augue nisi, tempus ac ultricies at, luctus at odio. Mauris efficitur vestibulum ante a scelerisque. Donec bibendum suscipit purus et tincidunt. Vivamus vitae velit id nunc elementum vulputate pellentesque vitae nibh. Praesent consectetur arcu ligula, eget porta sem semper a. Mauris leo eros, rutrum sed libero a, blandit maximus arcu. Nulla ornare, arcu ut volutpat hendrerit, sem sem posuere nibh, tempor vehicula quam nisl in purus. Fusce a sem at mi pharetra malesuada id vel erat. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Phasellus vel euismod justo. Etiam ornare diam tempor tristique dictum. Cras iaculis magna sapien.   </p>
                              </section>
                          </div>
                      </div>
                      <div class="col-md-2">
                          <div id="scrollspy">
                              <!-- Links -->
                              <ul class="nav flex-column nav-pills default-pills smooth-scroll" data-allow-hashes="">
                                  <li class="nav-item">
                                      <a class="nav-link" >Introduction</a>
                                  </li>
                                  <li class="nav-item">
                                      <a class="nav-link" >Overview</a>
                                  </li>
                                  <li class="nav-item">
                                      <a class="nav-link" >Examples</a>
                                  </li>
                              </ul>
                              <!-- Links -->
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
