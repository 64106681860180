<ngx-spinner type="ball-pulse">
  <p style="font-size: 20px; color: white">Computing result...</p>
</ngx-spinner>
<ng-template #regModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{title}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="white-space: pre-line;">
    {{message}}
  </div>
</ng-template>
<div class="container">
  <div class="accordion" id="accordionExample">
      <div class="card">
          <div class="card-header" id="headingOne">
              <div class="container pb-4 pt-4" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  <div class="align-items-center row">
                      <div class="col">
                          <hr class="border-secondary mb-0 mt-0">
                      </div>
                      <div class="col-auto">
                          <h4 class="font-weight-bold mb-0 text-uppercase">Input Section
                            <button type="button" class="btn btn-dark" style="border-radius: 50%; padding: 0px; width: 25px; height: 25px;"
                            (onShown)="logEvent('IS2','Input Section help opened')"
                            (onHidden)="logEvent('IS2','Input Section help closed')"
                            popover="Upload a file or write data. Data content must be numeric. Headers can be textual."
                            popoverTitle="Provide input"
                            triggers="mouseenter:mouseleave"
                            title=""
                            placement="right">
                            ?
                          </button>

                          </h4>

                      </div>
                      <div class="col">
                          <hr class="border-secondary mb-0 mt-0">
                      </div>
                  </div>
              </div>
          </div>
          <div id="collapseOne" class="collapse show" aria-labelledby="headingOne">
            <app-input></app-input>
          </div>
      </div>
      <div class="card">
          <div class="card-header" id="headingTwo">
              <div class="container pb-4 pt-4" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  <div class="align-items-center row">
                      <div class="col">
                          <hr class="border-secondary mb-0 mt-0"/>
                      </div>
                      <div class="col-auto">
                          <h4 class="font-weight-bold mb-0 text-uppercase">Algorithm Section
                            <button type="button" class="btn btn-dark" style="border-radius: 50%; padding: 0px; width: 25px; height: 25px;"
                            (onShown)="logEvent('AS2','Algorithm Section help opened')"
                            (onHidden)="logEvent('AS2','Algorithm Section help closed')"
                            popover="Choose an algorithm by exploring the drop-down menu."
                            popoverTitle="Select a Machine Learning Algorithm"
                            triggers="mouseenter:mouseleave"
                            title=""
                            placement="right">
                            ?
                            </button>
                          </h4>
                      </div>
                      <div class="col">
                          <hr class="border-secondary mb-0 mt-0"/>
                      </div>
                  </div>
              </div>
          </div>
          <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo">
              <div class="card-body">
                <div class="btn-group" dropdown [autoClose]="true" container="body" [insideClick]="true" (onShown)="logEvent('AS4','Select Algorithm menu opened')" (onHidden)="logEvent('AS4','Select Algorithm menu closed')" >
                  <button id="button-nested" dropdownToggle type="button" class="btn btn-secondary dropdown-toggle"
                          aria-controls="dropdown-nested">
                          Select Algorithm <span class="caret"></span>
                  </button>
                  <ng-template #popTemplate>

                    <h3 class="popover-title popover-header" style="margin: -.5rem -.75rem 0 -.75rem;">Choose an algorithm</h3>
                    Choose a machine learning algorithm from the menu. Please visit the links below to learn more about the algorithms.<br>
                    <li>Clustring
                      <ul>
                        <li><a href="https://computing4all.com/courses/introductory-data-science/lessons/k-means-clustering-algorithm/" target="_blank">K-Means</a></li>
                        <li><a href="https://iq.opengenus.org/dbscan-clustering-algorithm/" target="_blank">DB-Scan</a></li>
                        <li><a href="https://iq.opengenus.org/hierarchical-clustering/" target="_blank">Agglomerative</a></li>
                      </ul>
                    </li>
                    <li>Classification
                      <ul>
                      <li><a href="https://iq.opengenus.org/k-nearest-neighbors-algorithm/" target="_blank">K Nearest Neighbors</a></li>
                      <li><a href="https://iq.opengenus.org/gaussian-naive-bayes/" target="_blank">Gaussian Naive Bayes</a></li>
                      <li><a href="https://iq.opengenus.org/understand-support-vector-machine-in-depth/" target="_blank">Support Vector Machine</a></li>
                      </ul>
                    </li>

                    </ng-template>
                  <button type="button" class="btn btn-dark" style="border-radius: 50%; padding: 0px; width: 25px; height: 25px;"
                  (onShown)="logEvent('AS3','Select Algorithm menu help open')"
                  (onHidden)="logEvent('AS3','Select Algorithm menu help closed')"
                  [popover]="popTemplate"
                  title=""
                  placement="right" [outsideClick]="true">
                          ?
                  </button>
                  <script type="text/ng-template" id="popover.html">
                    <div>
                      <h1>Popover content</h1>
                    </div>
                  </script>
                  <ul id="dropdown-nested" *dropdownMenu class="dropdown-menu"
                      role="menu" aria-labelledby="button-nested">
                    <li role="menuitem" dropdown triggers="mouseclick" placement="right" container="body" (onShown)="logEvent('AS4','Clustering submenu opened')" (onHidden)="logEvent('AS4','Clustering submenu closed')">
                      <a dropdownToggle class="dropdown-item dropdown-toggle"
                        >Clustering <span class="caret"></span></a>
                      <ul *dropdownMenu class="dropdown-menu" role="menu">
                        <li role="menuitem"><a class="dropdown-item" (click)="setAlgorithm('K-Means')">K-Means</a></li>
                        <li role="menuitem"><a class="dropdown-item" (click)="setAlgorithm('DBSCAN')" >DBScan</a></li>
                        <li role="menuitem"><a class="dropdown-item" (click)="setAlgorithm('Agglomerative')">Agglomerative</a></li>
                      </ul>
                    </li>

                    <li role="menuitem" dropdown triggers="mouseclick" placement="right" container="body" (onShown)="logEvent('AS4','Classification submenu opened')" (onHidden)="logEvent('AS4','Classification submenu closed')">
                      <a dropdownToggle class="dropdown-item dropdown-toggle"
                         >Classification <span class="caret"></span></a>
                      <ul *dropdownMenu class="dropdown-menu" role="menu">
                        <li role="menuitem"><a class="dropdown-item" (click)="setAlgorithm('KNN')">K-Nearest Neighbors</a></li>
                        <li role="menuitem"><a class="dropdown-item" (click)="setAlgorithm('NaiveBayes')">Gaussian Naive Bayes</a></li>
                        <li role="menuitem"><a class="dropdown-item" (click)="setAlgorithm('SVM')">Support Vector Machine</a></li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <div class="card" [ngSwitch]="selectedAlgorithm">
                  <app-k-means *ngSwitchCase="'K-Means'" (algorithmEmitter)="runAlgorithm($event)"></app-k-means>
                  <app-db-scan *ngSwitchCase="'DBSCAN'" (algorithmEmitter)="runAlgorithm($event)"></app-db-scan>
                  <app-agglomerative *ngSwitchCase="'Agglomerative'" (algorithmEmitter)="runAlgorithm($event)"></app-agglomerative>
                  <app-classification *ngSwitchCase="'KNN'" (algorithmEmitter)="runAlgorithm($event)" [title]="'K-Nearest Neighbors'"></app-classification>
                  <app-classification *ngSwitchCase="'NaiveBayes'" (algorithmEmitter)="runAlgorithm($event)" [title]="'Gaussian Naive Bayes'"></app-classification>
                  <app-classification *ngSwitchCase="'SVM'" (algorithmEmitter)="runAlgorithm($event)" [title]="'Support Vector Machine'"></app-classification>
                </div>
              </div>
          </div>
      </div>
      <div class="card">
          <div class="card-header" id="headingThree">
              <div class="container pb-4 pt-4" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  <div class="align-items-center row">
                      <div class="col">
                          <hr class="border-secondary mb-0 mt-0"/>
                      </div>
                      <div class="col-auto">
                          <h4 class="font-weight-bold mb-0 text-uppercase">Results Section
                            <button type="button" class="btn btn-dark" style="border-radius: 50%; padding: 0px; width: 25px; height: 25px;"
                            popover="The results are provided here."
                            popoverTitle="Results Panel"
                            triggers="mouseenter:mouseleave"
                            title=""
                            placement="right">
                            ?
                          </button>
                          </h4>
                      </div>
                      <div class="col">
                          <hr class="border-secondary mb-0 mt-0"/>
                      </div>
                  </div>
              </div>
          </div>
          <div id="collapseThree"  aria-labelledby="headingThree"> <!--  class="collapse" -->
            <div class="card-body">
              <tabset>
                <tab *ngFor="let tabz of tabs"
                     [heading]="tabz.id"
                     [active]="tabz.active"
                     (selectTab)="tabz.active = true"
                     (deselect)="tabz.active = false"
                     [disabled]="tabz.disabled"
                     [removable]="true"
                     (removed)="removeTabHandler(tabz)"
                     [customClass]="tabz.customClass"
                     >
                     <div [ngSwitch]="tabz.type">
                      <app-kmeans-results *ngSwitchCase="'K-Means'" [data]="tabz.contentData" ></app-kmeans-results>
                      <app-dbscan-results *ngSwitchCase="'DBSCAN'"[data]="tabz.contentData"></app-dbscan-results>
                      <app-agglomerative-results *ngSwitchCase="'Agglomerative'" [data]="tabz.contentData" ></app-agglomerative-results>
                      <app-classification-results *ngSwitchCase="'KNN'" [data]="tabz.contentData" [title]="'KNN'"></app-classification-results>
                      <app-classification-results *ngSwitchCase="'NaiveBayes'" [data]="tabz.contentData" [title]="'NaiveBayes'"></app-classification-results>
                      <app-classification-results *ngSwitchCase="'SVM'" [data]="tabz.contentData" [title]="'SVM'"></app-classification-results>
                    </div>
                </tab>
              </tabset>
            </div>
          </div>
      </div>
  </div>
</div>
